import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5b68a5e6 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _079375d0 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _fd42e7ba = () => interopDefault(import('../pages/account/addresses/index.vue' /* webpackChunkName: "pages/account/addresses/index" */))
const _c69ed1b4 = () => interopDefault(import('../pages/account/compare/index.vue' /* webpackChunkName: "pages/account/compare/index" */))
const _5e7b81d2 = () => interopDefault(import('../pages/account/faq/index.vue' /* webpackChunkName: "pages/account/faq/index" */))
const _299346d0 = () => interopDefault(import('../pages/account/favorites/index.vue' /* webpackChunkName: "pages/account/favorites/index" */))
const _4b64c283 = () => interopDefault(import('../pages/account/id-card/index.vue' /* webpackChunkName: "pages/account/id-card/index" */))
const _2798dfe1 = () => interopDefault(import('../pages/account/loan-applications/index.vue' /* webpackChunkName: "pages/account/loan-applications/index" */))
const _2227dd9a = () => interopDefault(import('../pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _6a0551a7 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _fdfc822c = () => interopDefault(import('../pages/account/profile/index.vue' /* webpackChunkName: "pages/account/profile/index" */))
const _dd82d49a = () => interopDefault(import('../pages/account/profile/change-password/index.vue' /* webpackChunkName: "pages/account/profile/change-password/index" */))
const _038c1a6c = () => interopDefault(import('../pages/account/profile/change-phone/index.vue' /* webpackChunkName: "pages/account/profile/change-phone/index" */))
const _f0c37a48 = () => interopDefault(import('../pages/account/reviews/index.vue' /* webpackChunkName: "pages/account/reviews/index" */))
const _3db25f77 = () => interopDefault(import('../pages/account/subscriptions/index.vue' /* webpackChunkName: "pages/account/subscriptions/index" */))
const _cc8cc434 = () => interopDefault(import('../pages/account/transaction-history/index.vue' /* webpackChunkName: "pages/account/transaction-history/index" */))
const _3da62b0e = () => interopDefault(import('../pages/account/compare/_id.vue' /* webpackChunkName: "pages/account/compare/_id" */))
const _d24adfcc = () => interopDefault(import('../pages/account/loan-applications/_order_id.vue' /* webpackChunkName: "pages/account/loan-applications/_order_id" */))
const _dc5bed5a = () => interopDefault(import('../pages/account/orders/_order_id.vue' /* webpackChunkName: "pages/account/orders/_order_id" */))
const _62bfbd95 = () => interopDefault(import('../pages/become-partner/index.vue' /* webpackChunkName: "pages/become-partner/index" */))
const _646bf609 = () => interopDefault(import('../pages/benefits-umico-premium/index.vue' /* webpackChunkName: "pages/benefits-umico-premium/index" */))
const _2beecf0c = () => interopDefault(import('../pages/bingo-terms/index.vue' /* webpackChunkName: "pages/bingo-terms/index" */))
const _5bf19541 = () => interopDefault(import('../pages/bingo-terms-details/index.vue' /* webpackChunkName: "pages/bingo-terms-details/index" */))
const _fbf8531a = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _eb5fe722 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _58cbd519 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _209779fe = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _eabd07fa = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _94447c4c = () => interopDefault(import('../pages/credit_line.vue' /* webpackChunkName: "pages/credit_line" */))
const _33830286 = () => interopDefault(import('../pages/credit-limit-approved.vue' /* webpackChunkName: "pages/credit-limit-approved" */))
const _354c2946 = () => interopDefault(import('../pages/credit-test.vue' /* webpackChunkName: "pages/credit-test" */))
const _7476f24f = () => interopDefault(import('../pages/credit-verification.vue' /* webpackChunkName: "pages/credit-verification" */))
const _110e6b98 = () => interopDefault(import('../pages/crossborder-questions-answers.vue' /* webpackChunkName: "pages/crossborder-questions-answers" */))
const _bb811e26 = () => interopDefault(import('../pages/delivery-pudo-azerpoct/index.vue' /* webpackChunkName: "pages/delivery-pudo-azerpoct/index" */))
const _778de6ce = () => interopDefault(import('../pages/dukan-map/index.vue' /* webpackChunkName: "pages/dukan-map/index" */))
const _2bdcb0af = () => interopDefault(import('../pages/e-kassa-faq/index.vue' /* webpackChunkName: "pages/e-kassa-faq/index" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _35a3cb79 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _a4d25392 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _024c1f38 = () => interopDefault(import('../pages/how-to-buy/index.vue' /* webpackChunkName: "pages/how-to-buy/index" */))
const _5c0e9766 = () => interopDefault(import('../pages/how-to-place-order/index.vue' /* webpackChunkName: "pages/how-to-place-order/index" */))
const _ec4df3ec = () => interopDefault(import('../pages/how-to-sell/index.vue' /* webpackChunkName: "pages/how-to-sell/index" */))
const _12b1bfa0 = () => interopDefault(import('../pages/loyalty-program/index.vue' /* webpackChunkName: "pages/loyalty-program/index" */))
const _e354af6c = () => interopDefault(import('../pages/mega-discounts/index.vue' /* webpackChunkName: "pages/mega-discounts/index" */))
const _361bc460 = () => interopDefault(import('../pages/mobile-apps/index.vue' /* webpackChunkName: "pages/mobile-apps/index" */))
const _22e65104 = () => interopDefault(import('../pages/new-products/index.vue' /* webpackChunkName: "pages/new-products/index" */))
const _b66bcd40 = () => interopDefault(import('../pages/personal-recommendations/index.vue' /* webpackChunkName: "pages/personal-recommendations/index" */))
const _323da088 = () => interopDefault(import('../pages/pick-up-points/index.vue' /* webpackChunkName: "pages/pick-up-points/index" */))
const _6c64e405 = () => interopDefault(import('../pages/policy/index.vue' /* webpackChunkName: "pages/policy/index" */))
const _45299de8 = () => interopDefault(import('../pages/premium_account/index.vue' /* webpackChunkName: "pages/premium_account/index" */))
const _6c616e21 = () => interopDefault(import('../pages/premium_pros/index.vue' /* webpackChunkName: "pages/premium_pros/index" */))
const _a816e0ac = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _ab0824ec = () => interopDefault(import('../pages/product-reviews-rules/index.vue' /* webpackChunkName: "pages/product-reviews-rules/index" */))
const _471549f5 = () => interopDefault(import('../pages/promocodes_and_discounts/index.vue' /* webpackChunkName: "pages/promocodes_and_discounts/index" */))
const _8f72b96a = () => interopDefault(import('../pages/public-offer/index.vue' /* webpackChunkName: "pages/public-offer/index" */))
const _5eb5f856 = () => interopDefault(import('../pages/pudo-azerpoct/index.vue' /* webpackChunkName: "pages/pudo-azerpoct/index" */))
const _d85190d0 = () => interopDefault(import('../pages/questions-answers/index.vue' /* webpackChunkName: "pages/questions-answers/index" */))
const _ad475bcc = () => interopDefault(import('../pages/rules/index.vue' /* webpackChunkName: "pages/rules/index" */))
const _b1917e2e = () => interopDefault(import('../pages/sale-day/index.vue' /* webpackChunkName: "pages/sale-day/index" */))
const _6e39b2c3 = () => interopDefault(import('../pages/service-centres/index.vue' /* webpackChunkName: "pages/service-centres/index" */))
const _618edf7a = () => interopDefault(import('../pages/shipping-payment/index.vue' /* webpackChunkName: "pages/shipping-payment/index" */))
const _443af840 = () => interopDefault(import('../pages/shopping-public-offer/index.vue' /* webpackChunkName: "pages/shopping-public-offer/index" */))
const _523c4ab0 = () => interopDefault(import('../pages/sso_auth.vue' /* webpackChunkName: "pages/sso_auth" */))
const _2250f6b5 = () => interopDefault(import('../pages/start-selling-on-umico-market/index.vue' /* webpackChunkName: "pages/start-selling-on-umico-market/index" */))
const _1ee2f527 = () => interopDefault(import('../pages/subscribe-umico-premium/index.vue' /* webpackChunkName: "pages/subscribe-umico-premium/index" */))
const _cf9127a8 = () => interopDefault(import('../pages/tags/index.vue' /* webpackChunkName: "pages/tags/index" */))
const _56e5956c = () => interopDefault(import('../pages/test-page.vue' /* webpackChunkName: "pages/test-page" */))
const _646654a8 = () => interopDefault(import('../pages/top-products.vue' /* webpackChunkName: "pages/top-products" */))
const _08672202 = () => interopDefault(import('../pages/umico-delivery-service/index.vue' /* webpackChunkName: "pages/umico-delivery-service/index" */))
const _06f5d996 = () => interopDefault(import('../pages/umico-market-kredit/index.vue' /* webpackChunkName: "pages/umico-market-kredit/index" */))
const _db5e7106 = () => interopDefault(import('../pages/where-to-get/index.vue' /* webpackChunkName: "pages/where-to-get/index" */))
const _0284b2e2 = () => interopDefault(import('../pages/auth/authentication/index.vue' /* webpackChunkName: "pages/auth/authentication/index" */))
const _0c8e8315 = () => interopDefault(import('../pages/auth/login/index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _434f4929 = () => interopDefault(import('../pages/auth/lostpassword/index.vue' /* webpackChunkName: "pages/auth/lostpassword/index" */))
const _61dfb8ba = () => interopDefault(import('../pages/auth/registration/index.vue' /* webpackChunkName: "pages/auth/registration/index" */))
const _3183b3de = () => interopDefault(import('../pages/categories/seo_faq.js' /* webpackChunkName: "pages/categories/seo_faq" */))
const _1fa18810 = () => interopDefault(import('../pages/categories/seo_popular_products.js' /* webpackChunkName: "pages/categories/seo_popular_products" */))
const _096fcd04 = () => interopDefault(import('../pages/checkout/authentication/index.vue' /* webpackChunkName: "pages/checkout/authentication/index" */))
const _2210d30d = () => interopDefault(import('../pages/checkout/success/index.vue' /* webpackChunkName: "pages/checkout/success/index" */))
const _33a981b6 = () => interopDefault(import('../pages/docs/rules/index.vue' /* webpackChunkName: "pages/docs/rules/index" */))
const _7186183a = () => interopDefault(import('../pages/dukan-map/map.vue' /* webpackChunkName: "pages/dukan-map/map" */))
const _a8a51488 = () => interopDefault(import('../pages/merchant/seo_popular_products.js' /* webpackChunkName: "pages/merchant/seo_popular_products" */))
const _ca29f8b8 = () => interopDefault(import('../pages/payment/approve.vue' /* webpackChunkName: "pages/payment/approve" */))
const _b499581a = () => interopDefault(import('../pages/payment/cancel.vue' /* webpackChunkName: "pages/payment/cancel" */))
const _ceb73266 = () => interopDefault(import('../pages/payment/decline.vue' /* webpackChunkName: "pages/payment/decline" */))
const _508aaee6 = () => interopDefault(import('../pages/payment/refuse.vue' /* webpackChunkName: "pages/payment/refuse" */))
const _256bf55a = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _581854a6 = () => interopDefault(import('../pages/product/seo_micro.js' /* webpackChunkName: "pages/product/seo_micro" */))
const _10b4190d = () => interopDefault(import('../pages/tags/seo_popular_products.js' /* webpackChunkName: "pages/tags/seo_popular_products" */))
const _c43aca46 = () => interopDefault(import('../pages/auth/registration/verification/index.vue' /* webpackChunkName: "pages/auth/registration/verification/index" */))
const _0e9f8b9b = () => interopDefault(import('../pages/oauth/asan/success.vue' /* webpackChunkName: "pages/oauth/asan/success" */))
const _11616bea = () => interopDefault(import('../pages/brands/_brand.vue' /* webpackChunkName: "pages/brands/_brand" */))
const _eecd29b2 = () => interopDefault(import('../pages/categories/_slug.vue' /* webpackChunkName: "pages/categories/_slug" */))
const _579e5d89 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _1e542578 = () => interopDefault(import('../pages/keyword/_slug.vue' /* webpackChunkName: "pages/keyword/_slug" */))
const _542ef670 = () => interopDefault(import('../pages/merchant/_merchant.vue' /* webpackChunkName: "pages/merchant/_merchant" */))
const _95803d3a = () => interopDefault(import('../pages/partner/_slug.vue' /* webpackChunkName: "pages/partner/_slug" */))
const _1a8bdbef = () => interopDefault(import('../pages/pos/_slug.vue' /* webpackChunkName: "pages/pos/_slug" */))
const _247c0cca = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _77054dec = () => interopDefault(import('../pages/promo_page/_slug.vue' /* webpackChunkName: "pages/promo_page/_slug" */))
const _7487197e = () => interopDefault(import('../pages/promotion/_slug.vue' /* webpackChunkName: "pages/promotion/_slug" */))
const _4880f8f4 = () => interopDefault(import('../pages/search/_term.vue' /* webpackChunkName: "pages/search/_term" */))
const _27619c1e = () => interopDefault(import('../pages/tags/_tag.vue' /* webpackChunkName: "pages/tags/_tag" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _5b68a5e6,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _079375d0,
      pathToRegexpOptions: {"strict":true},
      name: "account___az"
    }, {
      path: "addresses",
      component: _fd42e7ba,
      pathToRegexpOptions: {"strict":true},
      name: "account-addresses___az"
    }, {
      path: "compare",
      component: _c69ed1b4,
      pathToRegexpOptions: {"strict":true},
      name: "account-compare___az"
    }, {
      path: "faq",
      component: _5e7b81d2,
      pathToRegexpOptions: {"strict":true},
      name: "account-faq___az"
    }, {
      path: "favorites",
      component: _299346d0,
      pathToRegexpOptions: {"strict":true},
      name: "account-favorites___az"
    }, {
      path: "id-card",
      component: _4b64c283,
      pathToRegexpOptions: {"strict":true},
      name: "account-id-card___az"
    }, {
      path: "loan-applications",
      component: _2798dfe1,
      pathToRegexpOptions: {"strict":true},
      name: "account-loan-applications___az"
    }, {
      path: "orders",
      component: _2227dd9a,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders___az"
    }, {
      path: "profile",
      component: _6a0551a7,
      pathToRegexpOptions: {"strict":true},
      children: [{
        path: "",
        component: _fdfc822c,
        pathToRegexpOptions: {"strict":true},
        name: "account-profile___az"
      }, {
        path: "change-password",
        component: _dd82d49a,
        pathToRegexpOptions: {"strict":true},
        name: "account-profile-change-password___az"
      }, {
        path: "change-phone",
        component: _038c1a6c,
        pathToRegexpOptions: {"strict":true},
        name: "account-profile-change-phone___az"
      }]
    }, {
      path: "reviews",
      component: _f0c37a48,
      pathToRegexpOptions: {"strict":true},
      name: "account-reviews___az"
    }, {
      path: "subscriptions",
      component: _3db25f77,
      pathToRegexpOptions: {"strict":true},
      name: "account-subscriptions___az"
    }, {
      path: "transaction-history",
      component: _cc8cc434,
      pathToRegexpOptions: {"strict":true},
      name: "account-transaction-history___az"
    }, {
      path: "compare/:id",
      component: _3da62b0e,
      pathToRegexpOptions: {"strict":true},
      name: "account-compare-id___az"
    }, {
      path: "loan-applications/:order_id?",
      component: _d24adfcc,
      pathToRegexpOptions: {"strict":true},
      name: "account-loan-applications-order_id___az"
    }, {
      path: "orders/:order_id",
      component: _dc5bed5a,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders-order_id___az"
    }]
  }, {
    path: "/become-partner",
    component: _62bfbd95,
    pathToRegexpOptions: {"strict":true},
    name: "become-partner___az"
  }, {
    path: "/benefits-umico-premium",
    component: _646bf609,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-umico-premium___az"
  }, {
    path: "/bingo-terms",
    component: _2beecf0c,
    pathToRegexpOptions: {"strict":true},
    name: "bingo-terms___az"
  }, {
    path: "/bingo-terms-details",
    component: _5bf19541,
    pathToRegexpOptions: {"strict":true},
    name: "bingo-terms-details___az"
  }, {
    path: "/cart",
    component: _fbf8531a,
    pathToRegexpOptions: {"strict":true},
    name: "cart___az"
  }, {
    path: "/categories",
    component: _eb5fe722,
    pathToRegexpOptions: {"strict":true},
    name: "categories___az"
  }, {
    path: "/checkout",
    component: _58cbd519,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___az"
  }, {
    path: "/companies",
    component: _209779fe,
    pathToRegexpOptions: {"strict":true},
    name: "companies___az"
  }, {
    path: "/contact",
    component: _eabd07fa,
    pathToRegexpOptions: {"strict":true},
    name: "contact___az"
  }, {
    path: "/credit_line",
    component: _94447c4c,
    pathToRegexpOptions: {"strict":true},
    name: "credit_line___az"
  }, {
    path: "/credit-limit-approved",
    component: _33830286,
    pathToRegexpOptions: {"strict":true},
    name: "credit-limit-approved___az"
  }, {
    path: "/credit-test",
    component: _354c2946,
    pathToRegexpOptions: {"strict":true},
    name: "credit-test___az"
  }, {
    path: "/credit-verification",
    component: _7476f24f,
    pathToRegexpOptions: {"strict":true},
    name: "credit-verification___az"
  }, {
    path: "/crossborder-questions-answers",
    component: _110e6b98,
    pathToRegexpOptions: {"strict":true},
    name: "crossborder-questions-answers___az"
  }, {
    path: "/delivery-pudo-azerpoct",
    component: _bb811e26,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-pudo-azerpoct___az"
  }, {
    path: "/dukan-map",
    component: _778de6ce,
    pathToRegexpOptions: {"strict":true},
    name: "dukan-map___az"
  }, {
    path: "/e-kassa-faq",
    component: _2bdcb0af,
    pathToRegexpOptions: {"strict":true},
    name: "e-kassa-faq___az"
  }, {
    path: "/en",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___az"
  }, {
    path: "/favorites",
    component: _a4d25392,
    pathToRegexpOptions: {"strict":true},
    name: "favorites___az"
  }, {
    path: "/how-to-buy",
    component: _024c1f38,
    pathToRegexpOptions: {"strict":true},
    name: "how-to-buy___az"
  }, {
    path: "/how-to-place-order",
    component: _5c0e9766,
    pathToRegexpOptions: {"strict":true},
    name: "how-to-place-order___az"
  }, {
    path: "/how-to-sell",
    component: _ec4df3ec,
    pathToRegexpOptions: {"strict":true},
    name: "how-to-sell___az"
  }, {
    path: "/loyalty-program",
    component: _12b1bfa0,
    pathToRegexpOptions: {"strict":true},
    name: "loyalty-program___az"
  }, {
    path: "/mega-discounts",
    component: _e354af6c,
    pathToRegexpOptions: {"strict":true},
    name: "mega-discounts___az"
  }, {
    path: "/mobile-apps",
    component: _361bc460,
    pathToRegexpOptions: {"strict":true},
    name: "mobile-apps___az"
  }, {
    path: "/new-products",
    component: _22e65104,
    pathToRegexpOptions: {"strict":true},
    name: "new-products___az"
  }, {
    path: "/personal-recommendations",
    component: _b66bcd40,
    pathToRegexpOptions: {"strict":true},
    name: "personal-recommendations___az"
  }, {
    path: "/pick-up-points",
    component: _323da088,
    pathToRegexpOptions: {"strict":true},
    name: "pick-up-points___az"
  }, {
    path: "/policy",
    component: _6c64e405,
    pathToRegexpOptions: {"strict":true},
    name: "policy___az"
  }, {
    path: "/premium_account",
    component: _45299de8,
    pathToRegexpOptions: {"strict":true},
    name: "premium_account___az"
  }, {
    path: "/premium_pros",
    component: _6c616e21,
    pathToRegexpOptions: {"strict":true},
    name: "premium_pros___az"
  }, {
    path: "/privacy-policy",
    component: _a816e0ac,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___az"
  }, {
    path: "/product-reviews-rules",
    component: _ab0824ec,
    pathToRegexpOptions: {"strict":true},
    name: "product-reviews-rules___az"
  }, {
    path: "/promocodes_and_discounts",
    component: _471549f5,
    pathToRegexpOptions: {"strict":true},
    name: "promocodes_and_discounts___az"
  }, {
    path: "/public-offer",
    component: _8f72b96a,
    pathToRegexpOptions: {"strict":true},
    name: "public-offer___az"
  }, {
    path: "/pudo-azerpoct",
    component: _5eb5f856,
    pathToRegexpOptions: {"strict":true},
    name: "pudo-azerpoct___az"
  }, {
    path: "/questions-answers",
    component: _d85190d0,
    pathToRegexpOptions: {"strict":true},
    name: "questions-answers___az"
  }, {
    path: "/ru",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___ru"
  }, {
    path: "/rules",
    component: _ad475bcc,
    pathToRegexpOptions: {"strict":true},
    name: "rules___az"
  }, {
    path: "/sale-day",
    component: _b1917e2e,
    pathToRegexpOptions: {"strict":true},
    name: "sale-day___az"
  }, {
    path: "/service-centres",
    component: _6e39b2c3,
    pathToRegexpOptions: {"strict":true},
    name: "service-centres___az"
  }, {
    path: "/shipping-payment",
    component: _618edf7a,
    pathToRegexpOptions: {"strict":true},
    name: "shipping-payment___az"
  }, {
    path: "/shopping-public-offer",
    component: _443af840,
    pathToRegexpOptions: {"strict":true},
    name: "shopping-public-offer___az"
  }, {
    path: "/sso_auth",
    component: _523c4ab0,
    pathToRegexpOptions: {"strict":true},
    name: "sso_auth___az"
  }, {
    path: "/start-selling-on-umico-market",
    component: _2250f6b5,
    pathToRegexpOptions: {"strict":true},
    name: "start-selling-on-umico-market___az"
  }, {
    path: "/subscribe-umico-premium",
    component: _1ee2f527,
    pathToRegexpOptions: {"strict":true},
    name: "subscribe-umico-premium___az"
  }, {
    path: "/tags",
    component: _cf9127a8,
    pathToRegexpOptions: {"strict":true},
    name: "tags___az"
  }, {
    path: "/test-page",
    component: _56e5956c,
    pathToRegexpOptions: {"strict":true},
    name: "test-page___az"
  }, {
    path: "/top-products",
    component: _646654a8,
    pathToRegexpOptions: {"strict":true},
    name: "top-products___az"
  }, {
    path: "/umico-delivery-service",
    component: _08672202,
    pathToRegexpOptions: {"strict":true},
    name: "umico-delivery-service___az"
  }, {
    path: "/umico-market-kredit",
    component: _06f5d996,
    pathToRegexpOptions: {"strict":true},
    name: "umico-market-kredit___az"
  }, {
    path: "/where-to-get",
    component: _db5e7106,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-get___az"
  }, {
    path: "/auth/authentication",
    component: _0284b2e2,
    pathToRegexpOptions: {"strict":true},
    name: "auth-authentication___az"
  }, {
    path: "/auth/login",
    component: _0c8e8315,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___az"
  }, {
    path: "/auth/lostpassword",
    component: _434f4929,
    pathToRegexpOptions: {"strict":true},
    name: "auth-lostpassword___az"
  }, {
    path: "/auth/registration",
    component: _61dfb8ba,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registration___az"
  }, {
    path: "/categories/seo_faq",
    component: _3183b3de,
    pathToRegexpOptions: {"strict":true},
    name: "categories-seo_faq___az"
  }, {
    path: "/categories/seo_popular_products",
    component: _1fa18810,
    pathToRegexpOptions: {"strict":true},
    name: "categories-seo_popular_products___az"
  }, {
    path: "/checkout/authentication",
    component: _096fcd04,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-authentication___az"
  }, {
    path: "/checkout/success",
    component: _2210d30d,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-success___az"
  }, {
    path: "/docs/rules",
    component: _33a981b6,
    pathToRegexpOptions: {"strict":true},
    name: "docs-rules___az"
  }, {
    path: "/dukan-map/map",
    component: _7186183a,
    pathToRegexpOptions: {"strict":true},
    name: "dukan-map-map___az"
  }, {
    path: "/en/account",
    component: _5b68a5e6,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _079375d0,
      pathToRegexpOptions: {"strict":true},
      name: "account___en"
    }, {
      path: "addresses",
      component: _fd42e7ba,
      pathToRegexpOptions: {"strict":true},
      name: "account-addresses___en"
    }, {
      path: "compare",
      component: _c69ed1b4,
      pathToRegexpOptions: {"strict":true},
      name: "account-compare___en"
    }, {
      path: "faq",
      component: _5e7b81d2,
      pathToRegexpOptions: {"strict":true},
      name: "account-faq___en"
    }, {
      path: "favorites",
      component: _299346d0,
      pathToRegexpOptions: {"strict":true},
      name: "account-favorites___en"
    }, {
      path: "id-card",
      component: _4b64c283,
      pathToRegexpOptions: {"strict":true},
      name: "account-id-card___en"
    }, {
      path: "loan-applications",
      component: _2798dfe1,
      pathToRegexpOptions: {"strict":true},
      name: "account-loan-applications___en"
    }, {
      path: "orders",
      component: _2227dd9a,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders___en"
    }, {
      path: "profile",
      component: _6a0551a7,
      pathToRegexpOptions: {"strict":true},
      children: [{
        path: "",
        component: _fdfc822c,
        pathToRegexpOptions: {"strict":true},
        name: "account-profile___en"
      }, {
        path: "change-password",
        component: _dd82d49a,
        pathToRegexpOptions: {"strict":true},
        name: "account-profile-change-password___en"
      }, {
        path: "change-phone",
        component: _038c1a6c,
        pathToRegexpOptions: {"strict":true},
        name: "account-profile-change-phone___en"
      }]
    }, {
      path: "reviews",
      component: _f0c37a48,
      pathToRegexpOptions: {"strict":true},
      name: "account-reviews___en"
    }, {
      path: "subscriptions",
      component: _3db25f77,
      pathToRegexpOptions: {"strict":true},
      name: "account-subscriptions___en"
    }, {
      path: "transaction-history",
      component: _cc8cc434,
      pathToRegexpOptions: {"strict":true},
      name: "account-transaction-history___en"
    }, {
      path: "compare/:id",
      component: _3da62b0e,
      pathToRegexpOptions: {"strict":true},
      name: "account-compare-id___en"
    }, {
      path: "loan-applications/:order_id?",
      component: _d24adfcc,
      pathToRegexpOptions: {"strict":true},
      name: "account-loan-applications-order_id___en"
    }, {
      path: "orders/:order_id",
      component: _dc5bed5a,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders-order_id___en"
    }]
  }, {
    path: "/en/become-partner",
    component: _62bfbd95,
    pathToRegexpOptions: {"strict":true},
    name: "become-partner___en"
  }, {
    path: "/en/benefits-umico-premium",
    component: _646bf609,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-umico-premium___en"
  }, {
    path: "/en/bingo-terms",
    component: _2beecf0c,
    pathToRegexpOptions: {"strict":true},
    name: "bingo-terms___en"
  }, {
    path: "/en/bingo-terms-details",
    component: _5bf19541,
    pathToRegexpOptions: {"strict":true},
    name: "bingo-terms-details___en"
  }, {
    path: "/en/cart",
    component: _fbf8531a,
    pathToRegexpOptions: {"strict":true},
    name: "cart___en"
  }, {
    path: "/en/categories",
    component: _eb5fe722,
    pathToRegexpOptions: {"strict":true},
    name: "categories___en"
  }, {
    path: "/en/checkout",
    component: _58cbd519,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___en"
  }, {
    path: "/en/companies",
    component: _209779fe,
    pathToRegexpOptions: {"strict":true},
    name: "companies___en"
  }, {
    path: "/en/contact",
    component: _eabd07fa,
    pathToRegexpOptions: {"strict":true},
    name: "contact___en"
  }, {
    path: "/en/credit_line",
    component: _94447c4c,
    pathToRegexpOptions: {"strict":true},
    name: "credit_line___en"
  }, {
    path: "/en/credit-limit-approved",
    component: _33830286,
    pathToRegexpOptions: {"strict":true},
    name: "credit-limit-approved___en"
  }, {
    path: "/en/credit-test",
    component: _354c2946,
    pathToRegexpOptions: {"strict":true},
    name: "credit-test___en"
  }, {
    path: "/en/credit-verification",
    component: _7476f24f,
    pathToRegexpOptions: {"strict":true},
    name: "credit-verification___en"
  }, {
    path: "/en/crossborder-questions-answers",
    component: _110e6b98,
    pathToRegexpOptions: {"strict":true},
    name: "crossborder-questions-answers___en"
  }, {
    path: "/en/delivery-pudo-azerpoct",
    component: _bb811e26,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-pudo-azerpoct___en"
  }, {
    path: "/en/dukan-map",
    component: _778de6ce,
    pathToRegexpOptions: {"strict":true},
    name: "dukan-map___en"
  }, {
    path: "/en/e-kassa-faq",
    component: _2bdcb0af,
    pathToRegexpOptions: {"strict":true},
    name: "e-kassa-faq___en"
  }, {
    path: "/en/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___en"
  }, {
    path: "/en/favorites",
    component: _a4d25392,
    pathToRegexpOptions: {"strict":true},
    name: "favorites___en"
  }, {
    path: "/en/how-to-buy",
    component: _024c1f38,
    pathToRegexpOptions: {"strict":true},
    name: "how-to-buy___en"
  }, {
    path: "/en/how-to-place-order",
    component: _5c0e9766,
    pathToRegexpOptions: {"strict":true},
    name: "how-to-place-order___en"
  }, {
    path: "/en/how-to-sell",
    component: _ec4df3ec,
    pathToRegexpOptions: {"strict":true},
    name: "how-to-sell___en"
  }, {
    path: "/en/loyalty-program",
    component: _12b1bfa0,
    pathToRegexpOptions: {"strict":true},
    name: "loyalty-program___en"
  }, {
    path: "/en/mega-discounts",
    component: _e354af6c,
    pathToRegexpOptions: {"strict":true},
    name: "mega-discounts___en"
  }, {
    path: "/en/mobile-apps",
    component: _361bc460,
    pathToRegexpOptions: {"strict":true},
    name: "mobile-apps___en"
  }, {
    path: "/en/new-products",
    component: _22e65104,
    pathToRegexpOptions: {"strict":true},
    name: "new-products___en"
  }, {
    path: "/en/personal-recommendations",
    component: _b66bcd40,
    pathToRegexpOptions: {"strict":true},
    name: "personal-recommendations___en"
  }, {
    path: "/en/pick-up-points",
    component: _323da088,
    pathToRegexpOptions: {"strict":true},
    name: "pick-up-points___en"
  }, {
    path: "/en/policy",
    component: _6c64e405,
    pathToRegexpOptions: {"strict":true},
    name: "policy___en"
  }, {
    path: "/en/premium_account",
    component: _45299de8,
    pathToRegexpOptions: {"strict":true},
    name: "premium_account___en"
  }, {
    path: "/en/premium_pros",
    component: _6c616e21,
    pathToRegexpOptions: {"strict":true},
    name: "premium_pros___en"
  }, {
    path: "/en/privacy-policy",
    component: _a816e0ac,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___en"
  }, {
    path: "/en/product-reviews-rules",
    component: _ab0824ec,
    pathToRegexpOptions: {"strict":true},
    name: "product-reviews-rules___en"
  }, {
    path: "/en/promocodes_and_discounts",
    component: _471549f5,
    pathToRegexpOptions: {"strict":true},
    name: "promocodes_and_discounts___en"
  }, {
    path: "/en/public-offer",
    component: _8f72b96a,
    pathToRegexpOptions: {"strict":true},
    name: "public-offer___en"
  }, {
    path: "/en/pudo-azerpoct",
    component: _5eb5f856,
    pathToRegexpOptions: {"strict":true},
    name: "pudo-azerpoct___en"
  }, {
    path: "/en/questions-answers",
    component: _d85190d0,
    pathToRegexpOptions: {"strict":true},
    name: "questions-answers___en"
  }, {
    path: "/en/rules",
    component: _ad475bcc,
    pathToRegexpOptions: {"strict":true},
    name: "rules___en"
  }, {
    path: "/en/sale-day",
    component: _b1917e2e,
    pathToRegexpOptions: {"strict":true},
    name: "sale-day___en"
  }, {
    path: "/en/service-centres",
    component: _6e39b2c3,
    pathToRegexpOptions: {"strict":true},
    name: "service-centres___en"
  }, {
    path: "/en/shipping-payment",
    component: _618edf7a,
    pathToRegexpOptions: {"strict":true},
    name: "shipping-payment___en"
  }, {
    path: "/en/shopping-public-offer",
    component: _443af840,
    pathToRegexpOptions: {"strict":true},
    name: "shopping-public-offer___en"
  }, {
    path: "/en/sso_auth",
    component: _523c4ab0,
    pathToRegexpOptions: {"strict":true},
    name: "sso_auth___en"
  }, {
    path: "/en/start-selling-on-umico-market",
    component: _2250f6b5,
    pathToRegexpOptions: {"strict":true},
    name: "start-selling-on-umico-market___en"
  }, {
    path: "/en/subscribe-umico-premium",
    component: _1ee2f527,
    pathToRegexpOptions: {"strict":true},
    name: "subscribe-umico-premium___en"
  }, {
    path: "/en/tags",
    component: _cf9127a8,
    pathToRegexpOptions: {"strict":true},
    name: "tags___en"
  }, {
    path: "/en/test-page",
    component: _56e5956c,
    pathToRegexpOptions: {"strict":true},
    name: "test-page___en"
  }, {
    path: "/en/top-products",
    component: _646654a8,
    pathToRegexpOptions: {"strict":true},
    name: "top-products___en"
  }, {
    path: "/en/umico-delivery-service",
    component: _08672202,
    pathToRegexpOptions: {"strict":true},
    name: "umico-delivery-service___en"
  }, {
    path: "/en/umico-market-kredit",
    component: _06f5d996,
    pathToRegexpOptions: {"strict":true},
    name: "umico-market-kredit___en"
  }, {
    path: "/en/where-to-get",
    component: _db5e7106,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-get___en"
  }, {
    path: "/merchant/seo_popular_products",
    component: _a8a51488,
    pathToRegexpOptions: {"strict":true},
    name: "merchant-seo_popular_products___az"
  }, {
    path: "/payment/approve",
    component: _ca29f8b8,
    pathToRegexpOptions: {"strict":true},
    name: "payment-approve___az"
  }, {
    path: "/payment/cancel",
    component: _b499581a,
    pathToRegexpOptions: {"strict":true},
    name: "payment-cancel___az"
  }, {
    path: "/payment/decline",
    component: _ceb73266,
    pathToRegexpOptions: {"strict":true},
    name: "payment-decline___az"
  }, {
    path: "/payment/refuse",
    component: _508aaee6,
    pathToRegexpOptions: {"strict":true},
    name: "payment-refuse___az"
  }, {
    path: "/payment/success",
    component: _256bf55a,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___az"
  }, {
    path: "/product/seo_micro",
    component: _581854a6,
    pathToRegexpOptions: {"strict":true},
    name: "product-seo_micro___az"
  }, {
    path: "/ru/account",
    component: _5b68a5e6,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _079375d0,
      pathToRegexpOptions: {"strict":true},
      name: "account___ru"
    }, {
      path: "addresses",
      component: _fd42e7ba,
      pathToRegexpOptions: {"strict":true},
      name: "account-addresses___ru"
    }, {
      path: "compare",
      component: _c69ed1b4,
      pathToRegexpOptions: {"strict":true},
      name: "account-compare___ru"
    }, {
      path: "faq",
      component: _5e7b81d2,
      pathToRegexpOptions: {"strict":true},
      name: "account-faq___ru"
    }, {
      path: "favorites",
      component: _299346d0,
      pathToRegexpOptions: {"strict":true},
      name: "account-favorites___ru"
    }, {
      path: "id-card",
      component: _4b64c283,
      pathToRegexpOptions: {"strict":true},
      name: "account-id-card___ru"
    }, {
      path: "loan-applications",
      component: _2798dfe1,
      pathToRegexpOptions: {"strict":true},
      name: "account-loan-applications___ru"
    }, {
      path: "orders",
      component: _2227dd9a,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders___ru"
    }, {
      path: "profile",
      component: _6a0551a7,
      pathToRegexpOptions: {"strict":true},
      children: [{
        path: "",
        component: _fdfc822c,
        pathToRegexpOptions: {"strict":true},
        name: "account-profile___ru"
      }, {
        path: "change-password",
        component: _dd82d49a,
        pathToRegexpOptions: {"strict":true},
        name: "account-profile-change-password___ru"
      }, {
        path: "change-phone",
        component: _038c1a6c,
        pathToRegexpOptions: {"strict":true},
        name: "account-profile-change-phone___ru"
      }]
    }, {
      path: "reviews",
      component: _f0c37a48,
      pathToRegexpOptions: {"strict":true},
      name: "account-reviews___ru"
    }, {
      path: "subscriptions",
      component: _3db25f77,
      pathToRegexpOptions: {"strict":true},
      name: "account-subscriptions___ru"
    }, {
      path: "transaction-history",
      component: _cc8cc434,
      pathToRegexpOptions: {"strict":true},
      name: "account-transaction-history___ru"
    }, {
      path: "compare/:id",
      component: _3da62b0e,
      pathToRegexpOptions: {"strict":true},
      name: "account-compare-id___ru"
    }, {
      path: "loan-applications/:order_id?",
      component: _d24adfcc,
      pathToRegexpOptions: {"strict":true},
      name: "account-loan-applications-order_id___ru"
    }, {
      path: "orders/:order_id",
      component: _dc5bed5a,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders-order_id___ru"
    }]
  }, {
    path: "/ru/become-partner",
    component: _62bfbd95,
    pathToRegexpOptions: {"strict":true},
    name: "become-partner___ru"
  }, {
    path: "/ru/benefits-umico-premium",
    component: _646bf609,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-umico-premium___ru"
  }, {
    path: "/ru/bingo-terms",
    component: _2beecf0c,
    pathToRegexpOptions: {"strict":true},
    name: "bingo-terms___ru"
  }, {
    path: "/ru/bingo-terms-details",
    component: _5bf19541,
    pathToRegexpOptions: {"strict":true},
    name: "bingo-terms-details___ru"
  }, {
    path: "/ru/cart",
    component: _fbf8531a,
    pathToRegexpOptions: {"strict":true},
    name: "cart___ru"
  }, {
    path: "/ru/categories",
    component: _eb5fe722,
    pathToRegexpOptions: {"strict":true},
    name: "categories___ru"
  }, {
    path: "/ru/checkout",
    component: _58cbd519,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___ru"
  }, {
    path: "/ru/companies",
    component: _209779fe,
    pathToRegexpOptions: {"strict":true},
    name: "companies___ru"
  }, {
    path: "/ru/contact",
    component: _eabd07fa,
    pathToRegexpOptions: {"strict":true},
    name: "contact___ru"
  }, {
    path: "/ru/credit_line",
    component: _94447c4c,
    pathToRegexpOptions: {"strict":true},
    name: "credit_line___ru"
  }, {
    path: "/ru/credit-limit-approved",
    component: _33830286,
    pathToRegexpOptions: {"strict":true},
    name: "credit-limit-approved___ru"
  }, {
    path: "/ru/credit-test",
    component: _354c2946,
    pathToRegexpOptions: {"strict":true},
    name: "credit-test___ru"
  }, {
    path: "/ru/credit-verification",
    component: _7476f24f,
    pathToRegexpOptions: {"strict":true},
    name: "credit-verification___ru"
  }, {
    path: "/ru/crossborder-questions-answers",
    component: _110e6b98,
    pathToRegexpOptions: {"strict":true},
    name: "crossborder-questions-answers___ru"
  }, {
    path: "/ru/delivery-pudo-azerpoct",
    component: _bb811e26,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-pudo-azerpoct___ru"
  }, {
    path: "/ru/dukan-map",
    component: _778de6ce,
    pathToRegexpOptions: {"strict":true},
    name: "dukan-map___ru"
  }, {
    path: "/ru/e-kassa-faq",
    component: _2bdcb0af,
    pathToRegexpOptions: {"strict":true},
    name: "e-kassa-faq___ru"
  }, {
    path: "/ru/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___ru"
  }, {
    path: "/ru/favorites",
    component: _a4d25392,
    pathToRegexpOptions: {"strict":true},
    name: "favorites___ru"
  }, {
    path: "/ru/how-to-buy",
    component: _024c1f38,
    pathToRegexpOptions: {"strict":true},
    name: "how-to-buy___ru"
  }, {
    path: "/ru/how-to-place-order",
    component: _5c0e9766,
    pathToRegexpOptions: {"strict":true},
    name: "how-to-place-order___ru"
  }, {
    path: "/ru/how-to-sell",
    component: _ec4df3ec,
    pathToRegexpOptions: {"strict":true},
    name: "how-to-sell___ru"
  }, {
    path: "/ru/loyalty-program",
    component: _12b1bfa0,
    pathToRegexpOptions: {"strict":true},
    name: "loyalty-program___ru"
  }, {
    path: "/ru/mega-discounts",
    component: _e354af6c,
    pathToRegexpOptions: {"strict":true},
    name: "mega-discounts___ru"
  }, {
    path: "/ru/mobile-apps",
    component: _361bc460,
    pathToRegexpOptions: {"strict":true},
    name: "mobile-apps___ru"
  }, {
    path: "/ru/new-products",
    component: _22e65104,
    pathToRegexpOptions: {"strict":true},
    name: "new-products___ru"
  }, {
    path: "/ru/personal-recommendations",
    component: _b66bcd40,
    pathToRegexpOptions: {"strict":true},
    name: "personal-recommendations___ru"
  }, {
    path: "/ru/pick-up-points",
    component: _323da088,
    pathToRegexpOptions: {"strict":true},
    name: "pick-up-points___ru"
  }, {
    path: "/ru/policy",
    component: _6c64e405,
    pathToRegexpOptions: {"strict":true},
    name: "policy___ru"
  }, {
    path: "/ru/premium_account",
    component: _45299de8,
    pathToRegexpOptions: {"strict":true},
    name: "premium_account___ru"
  }, {
    path: "/ru/premium_pros",
    component: _6c616e21,
    pathToRegexpOptions: {"strict":true},
    name: "premium_pros___ru"
  }, {
    path: "/ru/privacy-policy",
    component: _a816e0ac,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___ru"
  }, {
    path: "/ru/product-reviews-rules",
    component: _ab0824ec,
    pathToRegexpOptions: {"strict":true},
    name: "product-reviews-rules___ru"
  }, {
    path: "/ru/promocodes_and_discounts",
    component: _471549f5,
    pathToRegexpOptions: {"strict":true},
    name: "promocodes_and_discounts___ru"
  }, {
    path: "/ru/public-offer",
    component: _8f72b96a,
    pathToRegexpOptions: {"strict":true},
    name: "public-offer___ru"
  }, {
    path: "/ru/pudo-azerpoct",
    component: _5eb5f856,
    pathToRegexpOptions: {"strict":true},
    name: "pudo-azerpoct___ru"
  }, {
    path: "/ru/questions-answers",
    component: _d85190d0,
    pathToRegexpOptions: {"strict":true},
    name: "questions-answers___ru"
  }, {
    path: "/ru/rules",
    component: _ad475bcc,
    pathToRegexpOptions: {"strict":true},
    name: "rules___ru"
  }, {
    path: "/ru/sale-day",
    component: _b1917e2e,
    pathToRegexpOptions: {"strict":true},
    name: "sale-day___ru"
  }, {
    path: "/ru/service-centres",
    component: _6e39b2c3,
    pathToRegexpOptions: {"strict":true},
    name: "service-centres___ru"
  }, {
    path: "/ru/shipping-payment",
    component: _618edf7a,
    pathToRegexpOptions: {"strict":true},
    name: "shipping-payment___ru"
  }, {
    path: "/ru/shopping-public-offer",
    component: _443af840,
    pathToRegexpOptions: {"strict":true},
    name: "shopping-public-offer___ru"
  }, {
    path: "/ru/sso_auth",
    component: _523c4ab0,
    pathToRegexpOptions: {"strict":true},
    name: "sso_auth___ru"
  }, {
    path: "/ru/start-selling-on-umico-market",
    component: _2250f6b5,
    pathToRegexpOptions: {"strict":true},
    name: "start-selling-on-umico-market___ru"
  }, {
    path: "/ru/subscribe-umico-premium",
    component: _1ee2f527,
    pathToRegexpOptions: {"strict":true},
    name: "subscribe-umico-premium___ru"
  }, {
    path: "/ru/tags",
    component: _cf9127a8,
    pathToRegexpOptions: {"strict":true},
    name: "tags___ru"
  }, {
    path: "/ru/test-page",
    component: _56e5956c,
    pathToRegexpOptions: {"strict":true},
    name: "test-page___ru"
  }, {
    path: "/ru/top-products",
    component: _646654a8,
    pathToRegexpOptions: {"strict":true},
    name: "top-products___ru"
  }, {
    path: "/ru/umico-delivery-service",
    component: _08672202,
    pathToRegexpOptions: {"strict":true},
    name: "umico-delivery-service___ru"
  }, {
    path: "/ru/umico-market-kredit",
    component: _06f5d996,
    pathToRegexpOptions: {"strict":true},
    name: "umico-market-kredit___ru"
  }, {
    path: "/ru/where-to-get",
    component: _db5e7106,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-get___ru"
  }, {
    path: "/tags/seo_popular_products",
    component: _10b4190d,
    pathToRegexpOptions: {"strict":true},
    name: "tags-seo_popular_products___az"
  }, {
    path: "/auth/registration/verification",
    component: _c43aca46,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registration-verification___az"
  }, {
    path: "/en/auth/authentication",
    component: _0284b2e2,
    pathToRegexpOptions: {"strict":true},
    name: "auth-authentication___en"
  }, {
    path: "/en/auth/login",
    component: _0c8e8315,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___en"
  }, {
    path: "/en/auth/lostpassword",
    component: _434f4929,
    pathToRegexpOptions: {"strict":true},
    name: "auth-lostpassword___en"
  }, {
    path: "/en/auth/registration",
    component: _61dfb8ba,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registration___en"
  }, {
    path: "/en/categories/seo_faq",
    component: _3183b3de,
    pathToRegexpOptions: {"strict":true},
    name: "categories-seo_faq___en"
  }, {
    path: "/en/categories/seo_popular_products",
    component: _1fa18810,
    pathToRegexpOptions: {"strict":true},
    name: "categories-seo_popular_products___en"
  }, {
    path: "/en/checkout/authentication",
    component: _096fcd04,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-authentication___en"
  }, {
    path: "/en/checkout/success",
    component: _2210d30d,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-success___en"
  }, {
    path: "/en/docs/rules",
    component: _33a981b6,
    pathToRegexpOptions: {"strict":true},
    name: "docs-rules___en"
  }, {
    path: "/en/dukan-map/map",
    component: _7186183a,
    pathToRegexpOptions: {"strict":true},
    name: "dukan-map-map___en"
  }, {
    path: "/en/merchant/seo_popular_products",
    component: _a8a51488,
    pathToRegexpOptions: {"strict":true},
    name: "merchant-seo_popular_products___en"
  }, {
    path: "/en/payment/approve",
    component: _ca29f8b8,
    pathToRegexpOptions: {"strict":true},
    name: "payment-approve___en"
  }, {
    path: "/en/payment/cancel",
    component: _b499581a,
    pathToRegexpOptions: {"strict":true},
    name: "payment-cancel___en"
  }, {
    path: "/en/payment/decline",
    component: _ceb73266,
    pathToRegexpOptions: {"strict":true},
    name: "payment-decline___en"
  }, {
    path: "/en/payment/refuse",
    component: _508aaee6,
    pathToRegexpOptions: {"strict":true},
    name: "payment-refuse___en"
  }, {
    path: "/en/payment/success",
    component: _256bf55a,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___en"
  }, {
    path: "/en/product/seo_micro",
    component: _581854a6,
    pathToRegexpOptions: {"strict":true},
    name: "product-seo_micro___en"
  }, {
    path: "/en/tags/seo_popular_products",
    component: _10b4190d,
    pathToRegexpOptions: {"strict":true},
    name: "tags-seo_popular_products___en"
  }, {
    path: "/oauth/asan/success",
    component: _0e9f8b9b,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-asan-success___az"
  }, {
    path: "/ru/auth/authentication",
    component: _0284b2e2,
    pathToRegexpOptions: {"strict":true},
    name: "auth-authentication___ru"
  }, {
    path: "/ru/auth/login",
    component: _0c8e8315,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___ru"
  }, {
    path: "/ru/auth/lostpassword",
    component: _434f4929,
    pathToRegexpOptions: {"strict":true},
    name: "auth-lostpassword___ru"
  }, {
    path: "/ru/auth/registration",
    component: _61dfb8ba,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registration___ru"
  }, {
    path: "/ru/categories/seo_faq",
    component: _3183b3de,
    pathToRegexpOptions: {"strict":true},
    name: "categories-seo_faq___ru"
  }, {
    path: "/ru/categories/seo_popular_products",
    component: _1fa18810,
    pathToRegexpOptions: {"strict":true},
    name: "categories-seo_popular_products___ru"
  }, {
    path: "/ru/checkout/authentication",
    component: _096fcd04,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-authentication___ru"
  }, {
    path: "/ru/checkout/success",
    component: _2210d30d,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-success___ru"
  }, {
    path: "/ru/docs/rules",
    component: _33a981b6,
    pathToRegexpOptions: {"strict":true},
    name: "docs-rules___ru"
  }, {
    path: "/ru/dukan-map/map",
    component: _7186183a,
    pathToRegexpOptions: {"strict":true},
    name: "dukan-map-map___ru"
  }, {
    path: "/ru/merchant/seo_popular_products",
    component: _a8a51488,
    pathToRegexpOptions: {"strict":true},
    name: "merchant-seo_popular_products___ru"
  }, {
    path: "/ru/payment/approve",
    component: _ca29f8b8,
    pathToRegexpOptions: {"strict":true},
    name: "payment-approve___ru"
  }, {
    path: "/ru/payment/cancel",
    component: _b499581a,
    pathToRegexpOptions: {"strict":true},
    name: "payment-cancel___ru"
  }, {
    path: "/ru/payment/decline",
    component: _ceb73266,
    pathToRegexpOptions: {"strict":true},
    name: "payment-decline___ru"
  }, {
    path: "/ru/payment/refuse",
    component: _508aaee6,
    pathToRegexpOptions: {"strict":true},
    name: "payment-refuse___ru"
  }, {
    path: "/ru/payment/success",
    component: _256bf55a,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___ru"
  }, {
    path: "/ru/product/seo_micro",
    component: _581854a6,
    pathToRegexpOptions: {"strict":true},
    name: "product-seo_micro___ru"
  }, {
    path: "/ru/tags/seo_popular_products",
    component: _10b4190d,
    pathToRegexpOptions: {"strict":true},
    name: "tags-seo_popular_products___ru"
  }, {
    path: "/en/auth/registration/verification",
    component: _c43aca46,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registration-verification___en"
  }, {
    path: "/en/oauth/asan/success",
    component: _0e9f8b9b,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-asan-success___en"
  }, {
    path: "/ru/auth/registration/verification",
    component: _c43aca46,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registration-verification___ru"
  }, {
    path: "/ru/oauth/asan/success",
    component: _0e9f8b9b,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-asan-success___ru"
  }, {
    path: "/",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___az"
  }, {
    path: "/en/brands/:brand?",
    component: _11616bea,
    pathToRegexpOptions: {"strict":true},
    name: "brands-brand___en"
  }, {
    path: "/en/categories/:slug",
    component: _eecd29b2,
    pathToRegexpOptions: {"strict":true},
    name: "categories-slug___en"
  }, {
    path: "/en/category/:slug?",
    component: _579e5d89,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___en"
  }, {
    path: "/en/keyword/:slug?",
    component: _1e542578,
    pathToRegexpOptions: {"strict":true},
    name: "keyword-slug___en"
  }, {
    path: "/en/merchant/:merchant?",
    component: _542ef670,
    pathToRegexpOptions: {"strict":true},
    name: "merchant-merchant___en"
  }, {
    path: "/en/partner/:slug?",
    component: _95803d3a,
    pathToRegexpOptions: {"strict":true},
    name: "partner-slug___en"
  }, {
    path: "/en/pos/:slug?",
    component: _1a8bdbef,
    pathToRegexpOptions: {"strict":true},
    name: "pos-slug___en"
  }, {
    path: "/en/product/:slug?",
    component: _247c0cca,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___en"
  }, {
    path: "/en/promo_page/:slug?",
    component: _77054dec,
    pathToRegexpOptions: {"strict":true},
    name: "promo_page-slug___en"
  }, {
    path: "/en/promotion/:slug?",
    component: _7487197e,
    pathToRegexpOptions: {"strict":true},
    name: "promotion-slug___en"
  }, {
    path: "/en/search/:term?",
    component: _4880f8f4,
    pathToRegexpOptions: {"strict":true},
    name: "search-term___en"
  }, {
    path: "/en/tags/:tag",
    component: _27619c1e,
    pathToRegexpOptions: {"strict":true},
    name: "tags-tag___en"
  }, {
    path: "/ru/brands/:brand?",
    component: _11616bea,
    pathToRegexpOptions: {"strict":true},
    name: "brands-brand___ru"
  }, {
    path: "/ru/categories/:slug",
    component: _eecd29b2,
    pathToRegexpOptions: {"strict":true},
    name: "categories-slug___ru"
  }, {
    path: "/ru/category/:slug?",
    component: _579e5d89,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___ru"
  }, {
    path: "/ru/keyword/:slug?",
    component: _1e542578,
    pathToRegexpOptions: {"strict":true},
    name: "keyword-slug___ru"
  }, {
    path: "/ru/merchant/:merchant?",
    component: _542ef670,
    pathToRegexpOptions: {"strict":true},
    name: "merchant-merchant___ru"
  }, {
    path: "/ru/partner/:slug?",
    component: _95803d3a,
    pathToRegexpOptions: {"strict":true},
    name: "partner-slug___ru"
  }, {
    path: "/ru/pos/:slug?",
    component: _1a8bdbef,
    pathToRegexpOptions: {"strict":true},
    name: "pos-slug___ru"
  }, {
    path: "/ru/product/:slug?",
    component: _247c0cca,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___ru"
  }, {
    path: "/ru/promo_page/:slug?",
    component: _77054dec,
    pathToRegexpOptions: {"strict":true},
    name: "promo_page-slug___ru"
  }, {
    path: "/ru/promotion/:slug?",
    component: _7487197e,
    pathToRegexpOptions: {"strict":true},
    name: "promotion-slug___ru"
  }, {
    path: "/ru/search/:term?",
    component: _4880f8f4,
    pathToRegexpOptions: {"strict":true},
    name: "search-term___ru"
  }, {
    path: "/ru/tags/:tag",
    component: _27619c1e,
    pathToRegexpOptions: {"strict":true},
    name: "tags-tag___ru"
  }, {
    path: "/brands/:brand?",
    component: _11616bea,
    pathToRegexpOptions: {"strict":true},
    name: "brands-brand___az"
  }, {
    path: "/categories/:slug",
    component: _eecd29b2,
    pathToRegexpOptions: {"strict":true},
    name: "categories-slug___az"
  }, {
    path: "/category/:slug?",
    component: _579e5d89,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___az"
  }, {
    path: "/keyword/:slug?",
    component: _1e542578,
    pathToRegexpOptions: {"strict":true},
    name: "keyword-slug___az"
  }, {
    path: "/merchant/:merchant?",
    component: _542ef670,
    pathToRegexpOptions: {"strict":true},
    name: "merchant-merchant___az"
  }, {
    path: "/partner/:slug?",
    component: _95803d3a,
    pathToRegexpOptions: {"strict":true},
    name: "partner-slug___az"
  }, {
    path: "/pos/:slug?",
    component: _1a8bdbef,
    pathToRegexpOptions: {"strict":true},
    name: "pos-slug___az"
  }, {
    path: "/product/:slug?",
    component: _247c0cca,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___az"
  }, {
    path: "/promo_page/:slug?",
    component: _77054dec,
    pathToRegexpOptions: {"strict":true},
    name: "promo_page-slug___az"
  }, {
    path: "/promotion/:slug?",
    component: _7487197e,
    pathToRegexpOptions: {"strict":true},
    name: "promotion-slug___az"
  }, {
    path: "/search/:term?",
    component: _4880f8f4,
    pathToRegexpOptions: {"strict":true},
    name: "search-term___az"
  }, {
    path: "/tags/:tag",
    component: _27619c1e,
    pathToRegexpOptions: {"strict":true},
    name: "tags-tag___az"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
