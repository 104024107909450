import { defineStore } from 'pinia'
import { getResolutionName } from '@/utilities'
export const useUiStore = defineStore('ui', {
  state: () => ({
    resolution: 'sm',
    isQuickSearchOpen: false,
    isBirkartPopupVisible: false,
    isPosSidebarExpanded: true,
    isCatalogSidebarExpanded: true,
    isMobileAppsOverlayVisible: true,
    isPopupCartVisible: false,
    isPopupCartMobileVisible: false,
    isMapShow: true,
    isRootCatalogShow: false,
    fromBB: false,
    isSearchClientPopup: false,
    availablePopupVisible: false,
    isCheckoutWarningPopup: {
      visible: false,
      buttonFunc: null,
    },
    isBasketNotAvailablePopup: false,
    availableCountProduct: {},
    privacyPolicyVisibility: false,
    publicOfferVisibility: false,
    userAgreementVisibility: false,
    termsOfUsePaymentVisibility: false,
    isCompareRegisterPopup: {
      visible: false,
      product: {},
      add_to_comparison: {},
    },
    errorSomethingWentWrong: false,
    isMPAvailableRegisterPopup: {
      visible: false,
    },
    isFavoriteRegisterPopup: {
      visible: false,
      product: {},
      originalProduct: {},
      addToWishList: {},
    },
    isAnonymousRegisterPopup: {
      visible: false,
      status: false,
      phone: null,
    },
    isSearchVisible: false,
    isGeoLocationShow: false,
    isHeaderUserOpen: false,
    isCallCenterDropdownOpen: false,
    isCatalogMenuOpen: false,
    isProductsMenuOpen: false,
    isLangMenuOpen: false,
    isHamburgerOpen: false,
    quickSearchExpanded: false,
    showRateSuccessDialog: false,
    orderSuccessRateDialog: false,
    productSizeChartDialog: false,
    rateSuccessDialogData: null,
    creditLineBanner: true,
    showDateTimeNotSelected: false,
    registrationRequired: false,
    weReceivedYourRequest: false,
    showBulkPurchasePopup: false,
    showRepeatOrderModal: false,
    isPopupCompareMobileVisible: true,
    isPopupFavoriteMobileVisible: true,
    isPopupCartMinAmount: false,
    errorOrdersPackage: false,
    showBannerBonusBank: false,
    isPopupOtherOffersVisible: false,
    isPopupTagsCategoriesVisible: false,
    isPopupLottery: false,
    isPopupLotterySubscribe: false,
    prevRoute: {},
    isAdultPopupVisible: false,
    isQtyWarningPopup: {
      visible: false,
      qty: null,
    },
    inSufficientCreditModal: false,
    creditLineNotAvailableModal: false,
    availabilityGoodsSpecified: false,
    isPremiumQrCodeModalVisible: false,
    errorSingleId: false,
    showPopupAddReviw: false,
    testRedirectAppUrl: '',
    testRedirectType: '',
    popupAppMarket: {
      visible: false,
      link: '',
    },
  }),
  getters: {
    watchSidebars: state =>
      String(state.isPosSidebarExpanded) +
      String(state.isCatalogSidebarExpanded),
    fadeMain(state) {
      return (
        state.isQuickSearchOpen ||
        state.isGeoLocationShow ||
        state.isCatalogMenuOpen ||
        state.isProductsMenuOpen ||
        state.isHamburgerOpen ||
        state.isHeaderUserOpen ||
        state.privacyPolicyVisibility ||
        state.publicOfferVisibility ||
        state.userAgreementVisibility ||
        state.termsOfUsePaymentVisibility
      )
    },
    isMobile(state) {
      return state.resolution === 'sm'
    },
  },
  actions: {
    initResolution() {
      if (!window) return
      const width = document.body.clientWidth
      this.resolution = getResolutionName(width)
      window.addEventListener('resize', () => {
        const width = document.body.clientWidth
        this.resolution = getResolutionName(width)
      })
    },
    togglePosSidebar(payload = !this.isPosSidebarExpanded) {
      this.isPosSidebarExpanded = payload

      if (this.isPosSidebarExpanded) {
        document.body.classList.remove('Map_fullscreen')
        document.body.classList.add('POS_open')
      } else {
        document.body.classList.remove('POS_open')
        document.body.classList.add('Map_fullscreen')
      }
    },
    toggleCatalogSidebar(payload = !this.isCatalogSidebarExpanded) {
      this.isCatalogSidebarExpanded = payload

      if (this.isCatalogSidebarExpanded) {
        document.body.classList.remove('Map_fullscreen')
        document.body.classList.remove('POS_open')
        document.body.classList.add('Catalog_open')
      } else {
        document.body.classList.remove('Catalog_open')
        document.body.classList.add('Map_fullscreen')
      }
    },
    toggleQtyWarningPopup({ visible, qty }) {
      this.isQtyWarningPopup.visible = visible
      this.isQtyWarningPopup.qty = qty
    },
    clearFadeMain() {
      this.isQuickSearchOpen = false
      this.quickSearchExpanded = false
      this.isPopupCompareMobileVisible = false
      this.isPopupFavoriteMobileVisible = false
      this.isGeoLocationShow = false
      this.isCatalogMenuOpen = false
      this.isProductsMenuOpen = false
      this.isHamburgerOpen = false
      this.isHeaderUserOpen = false
      this.privacyPolicyVisibility = false
      this.publicOfferVisibility = false
      this.userAgreementVisibility = false
      this.termsOfUsePaymentVisibility = false
      this.isSearchVisible = false
      this.isPopupCartVisible = false
      this.isPopupCartMobileVisible = false
      this.isPopupCompareMobileVisible = false
      this.isPopupFavoriteMobileVisible = false
      this.isCallCenterDropdownOpen = false
      this.isLangMenuOpen = false
    },
    clearBurgerMenu() {
      this.isHeaderUserOpen = false
      this.isCatalogMenuOpen = false
      this.isCallCenterDropdownOpen = false
      this.isLangMenuOpen = false
    },
    setFavoriteRegisterPopup({
      visible,
      product,
      originalProduct,
      addToWishlist,
    }) {
      this.isFavoriteRegisterPopup = {
        visible,
        product,
        originalProduct,
        addToWishlist,
      }
    },
    closeFavoriteRegisterPopup() {
      this.isFavoriteRegisterPopup = {
        visible: false,
        product: {},
        originalProduct: {},
        addToWishList: {},
      }
    },
    setAnonymousRegisterPopup({ visible, status, phone }) {
      this.isAnonymousRegisterPopup = {
        visible,
        status,
        phone,
      }
    },
    closeAnonymousRegisterPopup() {
      this.isAnonymousRegisterPopup = {
        visible: false,
        status: false,
        phone: null,
      }
    },
    setAvailableRegisterPopup({ visible, product }) {
      this.isMPAvailableRegisterPopup = {
        visible,
        product,
      }
    },
    closeAvailableRegisterPopup() {
      this.isMPAvailableRegisterPopup = {
        visible: false,
        product: {},
      }
    },
  },
})
